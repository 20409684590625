import { useTranslation } from "next-i18next"

const useProductListi18n = ({ componentProps, total, generalRef }) => {
  const { t } = useTranslation()
  const i18n = {
    expanded: t("kf.productTab.aria.expanded"),
    collapsed: t("kf.productTab.aria.collapsed"),
    totalCount: t("kf.plp.label.totalCount", { count: total }),
    hideFilterAria: t("kf.plp.label.hideFilterAria"),
    showFilterAria: t("kf.plp.label.showFilterAria"),
    clearAllAria: t("kf.plp.label.clearAllAria"),
    listWith: t("kf.plp.label.listWith"),
    items: t("kf.plp.label.items"),
    facetListAriaExpandOrCollapse: t(
      "kf.plp.label.facetListAriaExpandOrCollapse"
    ),
    showMoreFiltersAria: t("kf.plp.label.showMoreFiltersAria"),
    sortByAria: count => t("kf.plp.label.sortByAria", { sortByCount: count }),
    sortBykey: t("kf.plp.label.sortBykey"),
    salePriceAria: t("kf.plp.label.salePriceAria"),
    originalPriceAria: t("kf.plp.label.originalPriceAria"),
    productDescription: t("kf.plp.label.productDescription"),
    colorsLinkAria: t("kf.plp.label.colorsLinkAria"),
    pageTitleAria: t("kf.plp.label.pageTitleAria", {
      categoryName: componentProps.categoryKey,
      brandName: generalRef?.brandName,
    }),
    print: t("kf.plp.label.print"),
    share: t("kf.plp.label.share"),
    wishlist: t("kf.plp.label.wishlist"),
    showFilter: t("kf.plp.label.showFilter"),
    hideFilter: t("kf.plp.label.hideFilter"),
    showFilterState: t("kf.plp.label.showFilterState"),
    hideFilterState: t("kf.plp.label.hideFilterState"),
    filters: t("kf.plp.label.filters"),
    filterSort: t("kf.plp.label.filterSort"),
    listviewFilter: t("kf.plp.label.listViewDeclare"),
    addToCompare: t("kf.plp.label.addToCompare"),
    colors: t("kf.plp.label.colors"),
    color: t("kf.plp.label.color"),
    view: t("kf.plp.label.view"),
    all: t("kf.plp.label.all"),
    gridView: t("kf.plp.label.gridView"),
    columnView: t("kf.plp.label.columnView"),
    sort: t("kf.plp.label.sort"),
    sortbyExpanded: t("kf.plp.label.sortbyExpanded"),
    sortbyClosed: t("kf.plp.label.sortbyClosed"),
    selected: t("kf.plp.arialabel.selected"),
    altSort: t("kf.plp.label.img.altSort"),
    applyFilters: "Apply Filters",
    results: t("kf.plp.label.results"),
    annsacksResults: t("kf.plp.label.annsacksResults"),
    loadMore: t("kf.plp.label.loadMore"),
    backToTop: t("kf.plp.label.backToTop"),
    showMore: componentProps.isAnnsacksPlp
      ? t("annsacks.plp.label.showMore")
      : t("kf.plp.label.showMore"),
    showLess: componentProps.isAnnsacksPlp
      ? t("annsacks.plp.label.showLess")
      : t("kf.plp.label.showLess"),
    clearAll: t("kf.plp.label.clearAll"),
    priceRangeTitle: t("kf.plp.label.priceRangeTitle"),
    priceRangeDesc: t("kf.plp.label.priceRangeDesc"),
    to: t("kf.plp.label.to"),
    minText: t("kf.plp.label.minText"),
    maxText: t("kf.plp.label.maxText"),
    sameCategory: componentProps.sameCategory
      ? componentProps.sameCategory
      : t("kf.compare.validation.sameCategory"),
    maxProduct: componentProps.maxProduct
      ? componentProps.maxProduct
      : t("kf.compare.validation.maxProducts"),
    favSuccessMsg: t("kf.favorites.success"),
    favErrorMsg: t("kf.favorites.error"),
    errors: {
      validRange: t("kf.plp.error.validRange"),
      minGreaterThanMax: t("kf.plp.error.minGreaterThanMax"),
      maxGreaterThanZero: t("kf.plp.error.maxGreaterThanZero"),
      checkValid: t("kf.error.message.price"),
    },
    ariaLabel: {
      columnViewSelected: t("kf.productTab.aria.columnViewSelected"),
      columnViewNotSelected: t("kf.productTab.aria.columnViewNotSelected"),
      gridViewSelected: t("kf.productTab.aria.gridViewSelected"),
      gridViewNotSelected: t("kf.productTab.aria.gridViewNotSelected"),
    },
    startingAt: t("kf.plp.label.startingAt"),
    viewResults: t("kf.plp.label.viewResults"),
    new: generalRef?.badgeText.new
      ? generalRef?.badgeText.new
      : t("kf.plp.label.new"),
    onSale: generalRef?.badgeText.onSale
      ? generalRef?.badgeText.onSale
      : t("kf.plp.label.onSale"),
    exclusive: generalRef?.badgeText.exclusive
      ? generalRef?.badgeText.exclusive
      : t("kf.plp.label.exclusive"),
    newExclusive: t("kf.plp.label.newExclusive"),
    filterOpenAlert: t("kf.plp.ariaLabel.filterOpenAlert"),
    filterCloseAlert: t("kf.plp.ariaLabel.filterCloseAlert"),
    removeItem: t("kf.favorites.removeItem"),
    removeItemError: t("kf.favorites.removeItemError"),
    noResultsMssg:
      componentProps.noResultsMsg ?? t("kf.plp.label.noResultsMssg"),
    featured: t("kf.plp.label.featured"),
    // featured: "Featured",
    priceDesc: t("kf.plp.label.priceDesc"),
    priceAsc: t("kf.plp.label.priceAsc"),
    atoz: t("kf.plp.label.atoz"),
    ztoa: t("kf.plp.label.ztoa"),
    // extras
    newToOld: t("kf.myProducts.sort.newToOld"),
    oldToNew: t("kf.myProducts.sort.oldToNew"),
    // needtoCheck check in getSelectedSort helper.js
    AToZ: t("kf.services.sort.AToZ"),
    ZToA: t("kf.services.sort.ZToA"),
    filterTitle: (facetName, facetCount) =>
      t("kf.plp.ariaLabel.filterTitle", {
        facetName: facetName,
        facetCount: facetCount,
      }),
    arialLabelPrint: val => t("kf.plp.label.aria.print", { val: val }),
    ariaLabelShare: val =>
      t("kf.plp.label.aria.share", {
        val: `${val} page`,
      }),
  }

  return i18n
}

export default useProductListi18n
