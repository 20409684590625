import { apim } from "@/constants/api"
import {
  getUserAnalyticsObject,
  isShowAnalyticsEventLoaded,
  sanitizeTextForAnalytics,
} from "@/utils/helper"
import { SEAT_TYPES } from "@/constants"

const addAnalyticsShowEvent = (promo, setPromoData, data = []) => {
  let promoData = promo.filter(promo => promo && promo.ctaLink)
  promoData.sort((a, b) => a.position - b.position)
  promoData = getUniqueList(promoData, "position")
  let slot = 1
  const component = []
  promoData.forEach(promoItem => {
    let title = promoItem.desc || ""
    if (title) {
      const div = document.createElement("div")
      div.innerHTML = title
      // This contains HTML code so remove some bugs here.
      title = (div.innerText || div.textContent || "")?.toLowerCase()
      title = title.replace(/\n/g, "")
    }
    promoItem.componentInfo = {
      componentID: `${window.location.origin}${promoItem.ctaLink}`,
      componentMessage: `promo-${slot++}`,
      componentName: title,
      componentLocation: "plp:promo tiles",
      componentType: "internal campaign",
    }
    component.push({
      componentInfo: promoItem.componentInfo,
    })
  })

  setPromoData({ promos: promoData, isUpdate: false })

  if (!isShowAnalyticsEventLoaded()) {
    const page = window.pageObj || {}
    page.user = getUserAnalyticsObject()
    if (page.category) {
      page.category.room =
        data && data[0] && data[0].category1_s
          ? sanitizeTextForAnalytics(data[0].category1_s)
          : "n/a"
    } else {
      page.category = {
        room:
          data && data[0] && data[0].category1_s
            ? sanitizeTextForAnalytics(data[0].category1_s)
            : "n/a",
      }
    }
    if (
      (!page.user.gId || page.user.gId === "n/a") &&
      (!page.user.commerceToolsID || page.user.commerceToolsID === "n/a")
    ) {
      page.component = component
      window.pageObj = page
      window.isShowAnalyticsEvent = true
    } else {
      window.adobeDataLayer.push({
        event: "cmp:show",
        page: page,
        component: component,
      })
    }
  } else {
    const page = window.pageObj
    page.category.subCategoryName = SEAT_TYPES?.includes(
      data[0]?.Product_Category
    )
      ? SEAT_TYPES?.join(", ")
      : data[0]?.Product_Category
  }
}

const getUniqueList = (arr, key) => {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

const getClickSignalPayload = ({
  highlightText,
  productCategory,
  siteName,
  sku,
  ...props
}) => {
  const userInfo =
    window.adobeDataLayer.getState("page.user.authStatus") === "anonymous"
      ? "guest"
      : window.adobeDataLayer.getState("page.user.commerceToolsID")
  const sTimeStamp =
    new Date().toISOString().split("T")[0] +
    "T" +
    new Date().toTimeString().split(" ")[0] +
    "Z"
  return [
    {
      params: {
        query: highlightText ?? productCategory,
        app_id: siteName.toLowerCase(),
        doc_id: props.data.id,
        fusion_query_id: props.fusionQueryId,
        ctype: props.parent,
        user_id: userInfo,
        country: "USA",
        region: "Northeast",
        item_pos: props.index,
        plc: productCategory,
        sku_id: props.data.sku_s,
        session_id: props?.sessionId,
        count_i: "1",
        url: window.location,
      },
      id: `${new Date().valueOf()}_${userInfo}_${sku} `,
      timestamp: sTimeStamp,
      type: "click",
    },
  ]
}

const triggerClickSignal = async ({
  highlightText,
  productCategory,
  siteName,
  sku,
  ...props
}) => {
  const aSignalsPayload = getClickSignalPayload({
    highlightText,
    productCategory,
    siteName,
    sku,
    ...props,
  })
  await apim.post(`/search/signals/${siteName.toLowerCase()}`, aSignalsPayload)
}

export { addAnalyticsShowEvent, getClickSignalPayload, triggerClickSignal }
