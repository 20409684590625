export const keyPress = (e, filterModalRef, cb) => {
  const focusElements =
    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]), button:not([disabled]) [role="button]:not([hidden=true])'
  const focusContent = filterModalRef.current.querySelectorAll(focusElements)
  const firstElem = focusContent[0]
  const lastElem = focusContent[focusContent.length - 1].disabled
    ? focusContent[focusContent.length - 2]
    : focusContent[focusContent.length - 1]
  if (e.key === "Escape") {
    cb(false)
  }
  if (e.key === "Tab") {
    if (
      e.shiftKey &&
      (document.activeElement === firstElem ||
        document.activeElement === filterModalRef.current)
    ) {
      e.preventDefault()
      lastElem.focus()
    }
    if (!e.shiftKey && document.activeElement === lastElem) {
      e.preventDefault()
      firstElem.focus()
    }
  }
}

export const getIsPresentOnDevice = (isHideOnDesktop, isHideOnMobile) => {
  if (isHideOnMobile === "true" && isHideOnDesktop === "true") {
    return false
  }
  let showDefaultBehaviour =
    isHideOnDesktop === undefined && isHideOnMobile === undefined
  showDefaultBehaviour =
    showDefaultBehaviour ||
    (isHideOnMobile === "false" && isHideOnDesktop === "false")
  if (showDefaultBehaviour) {
    return true
  }

  const isPresentOnDeskTop = window.matchMedia("(min-width: 1280px)").matches
  if (isHideOnDesktop === "true") {
    return isPresentOnDeskTop
  }

  return isHideOnMobile === "true" ? !isPresentOnDeskTop : isPresentOnDeskTop
}
