import React, { useRef, useState, useEffect } from "react"
import cx from "classnames"
import DOMPurify from "isomorphic-dompurify"
import _get from "lodash/get"
import { useSelector } from "react-redux"
import StickyNavHeader from "@/components/ProductList/v2/ProductListView/StickyNav/stickyNavHeader"
import useWindowResize from "@/hooks/useWindowResize"
import {
  keyPress,
  getIsPresentOnDevice,
} from "@/components/ProductList/v2/ProductListView/productListHelper"
import Filter from "@/components/ProductList/v2/Filter"
import styles from "@/components/ProductList/v2/ProductListView/index.module.scss"
import productTileStyles from "@/components/ProductList/v2/ProductTile/index.module.scss"
import sortStyle from "@/components/ProductList/v2/Sort/index.module.scss"
import filterStyle from "@/components/ProductList/v2/Filter/index.module.scss"
import ProductPagination from "@/components/ProductList/v2/ProductListView/Pagination"
import ProductTile from "@/components/ProductList/v2/ProductTile"
import { getSwatchCount, organizeData } from "@/utils/helper"
import { isMobile, isTablet } from "react-device-detect"
import { addToCompareProduct, removeFromCompareProduct } from "@/utils/product"
import PromoBanner from "@/components/PromoBanner/v2/PromoBanner"
import PartsViewer from "@/components/PartsViewer/v1/PartsViewer"
import PartsViewerV2 from "@/components/PartsViewer/v2/PartsViewer"
import { selectGenericState } from "@/store/features/genericSlice"
import useSmartCrop from "@/hooks/useSmartCrop"
import LazyLoad from "react-lazy-load"

const ProductListView = props => {
  const {
    facets,
    filterview = "showFilter",
    staticTexts,
    sorts,
    selectedSort,
    loading,
    data,
    onSort,
    onFilter,
    totalResults,
    onLoadMore,
    curRows,
    displayPrint,
    displayShare,
    onRows,
    productsPerPage,
    facetLabels,
    colors,
    info,
    isLandscape,
    defaultImageTag,
    hoverImageTag,
    favorites,
    currFilters,
    presetConfigs,
    fusionQueryId,
    isGrouped,
    promoLayout,
    xfHtml,
    promoPlacementMobile,
    promoPlacementDesktop,
    compareData,
    promoJson,
    setPromoData,
    promoData,
    sessionId = "",
    plpBadge = {},
    presentOnDesktop = "",
    presentOnMobile = "",
  } = props
  const { currencySign } = info
  const [width] = useWindowResize()
  const smartCrop = useSmartCrop()
  const initialPartData = {
    productId: "",
    partId: "",
    isShowModal: false,
  }
  const [swatchCount, setSwatchCount] = useState(width > 812 ? 7 : 5)
  const plpRef = useRef(null)
  const filterModalWindow = useRef(null)
  const [showFilter, setShowFilter] = useState(
    width >= 992 ? filterview === "showFilter" : false
  )

  const [rowCols, setRowCols] = useState(width <= 1280 ? 2 : 3)
  const [partData, setPartsData] = useState({ ...initialPartData })
  const [svgFileMap, setSvgFileMap] = useState([])
  const { featureFlags } = useSelector(selectGenericState)
  const {
    MYSQLCONNSTR_ENABLE_PARTS_VIEWER_V2: isPartsViewerV2Enabled = false,
  } = featureFlags
  const filterClass = cx({
    "product-listing__filter": true,
    "product-listing__filter--hide": !showFilter,
    "product-listing__filter--modal": showFilter,
  })

  const tileClass = cx({
    "product-listing__tile-col": true,
    "product-listing__tile-col--full-width": !showFilter,
  })
  useEffect(() => {
    if (width <= 1280) {
      setRowCols(2)
    } else {
      setRowCols(3)
    }
  }, [isLandscape, width])

  useEffect(() => {
    setShowFilter(width >= 992 ? filterview === "showFilter" : false)
  }, [filterview])

  useEffect(() => {
    if (!showFilter && (width < 992 || isMobile || isTablet)) {
      document.getElementById("showFilter")?.focus()
    }
  }, [showFilter])

  // calculate promo position
  const col =
    width < 768
      ? {
          "one-column": "promo-col-3",
          "two-column": "promo-col-3",
          "three-column": "promo-col-3",
        }
      : width > 768 && width < 1281
      ? {
          "one-column": "promo-col-1",
          "two-column": "promo-col-3",
          "three-column": "promo-col-3",
        }
      : {
          "one-column": "promo-col-1",
          "two-column": "promo-col-2",
          "three-column": "promo-col-3",
        }
  const colVal = { "one-column": 1, "two-column": 2, "three-column": 3 }
  const row = isMobile
    ? parseInt(promoPlacementMobile)
    : parseInt(promoPlacementDesktop)
  const position =
    width <= 1280
      ? rowCols === 1
        ? row - 1
        : row * rowCols - (colVal[promoLayout] === 1 && width > 767 ? 1 : 2)
      : row * rowCols - colVal[promoLayout]

  const executeScroll = () => {
    plpRef?.current?.scrollIntoView({ behavior: "smooth" })
  }

  useEffect(() => {
    const handleResize = () => setSwatchCount(getSwatchCount(true))
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const handleKeyPress = e => {
    if (width < 992) {
      keyPress(e, filterModalWindow, setShowFilter)
    }
  }

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    document.getElementById(`kf-product-list-tile-details-0`)?.focus()
  }
  const handleSort = sort => {
    onSort(sort)
    executeScroll()
  }

  const handleOnClickPartsDiagramLink = data => {
    const getPartId = _get(
      data,
      "ProductResource.InteractiveSVG.resourceName_ss",
      ""
    )
    const resourceTitles = _get(
      data,
      "ProductResource.InteractiveSVG.resourceTitle_ss",
      ""
    )
    const partId = Array.isArray(getPartId) ? getPartId[0] : getPartId
    setPartsData({
      partId,
      productId: data?.ProductProductNo_s,
      isShowModal: true,
    })
    const partLinks = organizeData(
      getPartId,
      resourceTitles,
      "Service Parts Diagram",
      "PART"
    )
    setSvgFileMap(partLinks)
  }

  const onCloseModal = () => {
    setPartsData({
      ...initialPartData,
      isShowModal: false,
    })
  }

  let promoIndex = 0
  return (
    <div
      className={`${styles.productListWrapper} ${productTileStyles.productTileWrapper} ${sortStyle.productListingSearchWrapper} ${filterStyle.productListingFilterWrapper}`}
    >
      <div className="product-listing" ref={plpRef}>
        <StickyNavHeader
          showFilter={showFilter}
          rowCols={rowCols}
          setShowFilter={setShowFilter}
          setRowCols={setRowCols}
          staticTexts={staticTexts}
          sorts={sorts}
          selectedSort={selectedSort}
          handleSort={handleSort}
          totalResults={totalResults}
        />
        <div className="container kf-react-plp-container product-listing__tiles">
          <div className="product-listing__row d-block">
            <div className="filter-col">
              <div className={filterClass} aria-modal ref={filterModalWindow}>
                <div
                  className={`product-listing__filter--sticky`}
                  aria-hidden={!showFilter}
                  onKeyDown={handleKeyPress}
                >
                  {facets && (
                    <Filter
                      tabIndex="0"
                      texts={staticTexts}
                      close={setShowFilter}
                      showFilter={showFilter}
                      addToUrl={true}
                      showSort={width <= 991}
                      sort={sorts}
                      selectedSort={selectedSort}
                      facetData={facets}
                      totalResults={totalResults}
                      loading={loading}
                      colors={colors}
                      facetLabels={facetLabels}
                      onFilter={onFilter}
                      onSortChange={handleSort}
                      displayShare={displayShare}
                      displayPrint={displayPrint}
                      currFilters={currFilters}
                    />
                  )}
                </div>
              </div>
              <div className={tileClass}>
                {data?.length === 0 ? (
                  <div
                    className="product-listing__no-results"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(staticTexts?.noResultsMssg),
                    }}
                  />
                ) : (
                  <>
                    {/* #ToDo Modified the promotion displaying logic it was (i + 4)/position in aem foundation but is is not working as expected*/}
                    <div className={`product-listing__row row-cols-${rowCols}`}>
                      {data?.map((product, i) => (
                        // console.log( xfHtml && (i + 1) % position === 0)
                        <React.Fragment
                          key={`${i}-${product.Product_Category}-${product.ctId_s}`}
                        >
                          <div
                            className="product-listing__tile"
                            key={`${i}-${product.Product_Category}-${product.ctId_s}`}
                            id={product["masterCustomerFacingSKU_s"]}
                          >
                            {i < 3 ? (
                              <React.Fragment
                                key={`${i}-${product.Product_Category}-${product.ctId_s}-LAZY`}
                              >
                                <ProductTile
                                  rowCols={rowCols}
                                  tabIndex="0"
                                  loading={loading}
                                  data={product}
                                  mapImageToColor
                                  texts={staticTexts}
                                  lite={
                                    !isLandscape && isMobile && rowCols === 2
                                  }
                                  addToCompare={addToCompareProduct}
                                  removeFromCompare={removeFromCompareProduct}
                                  compareData={compareData}
                                  defaultImage={defaultImageTag}
                                  hoverImage={hoverImageTag}
                                  currencySign={currencySign}
                                  isGrouped={isGrouped}
                                  swatchCount={swatchCount}
                                  favorites={favorites}
                                  currFilters={currFilters}
                                  selectedSort={selectedSort}
                                  plpPage={true}
                                  index={i}
                                  presetConfigs={presetConfigs}
                                  fusionQueryId={fusionQueryId}
                                  parent="PLP"
                                  sessionId={sessionId}
                                  plpBadge={plpBadge}
                                  isPartsDiagramLinkVisible
                                  handleOnClickPartsDiagramLink={() => {
                                    handleOnClickPartsDiagramLink(product)
                                  }}
                                />
                              </React.Fragment>
                            ) : (
                              <React.Fragment
                                key={`${i}-${product.Product_Category}-${product.ctId_s}-LAZY`}
                              >
                                <LazyLoad className="kf-lazyload-wrapper">
                                  <ProductTile
                                    rowCols={rowCols}
                                    tabIndex="0"
                                    loading={loading}
                                    data={product}
                                    mapImageToColor
                                    texts={staticTexts}
                                    lite={
                                      !isLandscape && isMobile && rowCols === 2
                                    }
                                    addToCompare={addToCompareProduct}
                                    removeFromCompare={removeFromCompareProduct}
                                    compareData={compareData}
                                    defaultImage={defaultImageTag}
                                    hoverImage={hoverImageTag}
                                    currencySign={currencySign}
                                    isGrouped={isGrouped}
                                    swatchCount={swatchCount}
                                    favorites={favorites}
                                    currFilters={currFilters}
                                    selectedSort={selectedSort}
                                    plpPage={true}
                                    index={i}
                                    presetConfigs={presetConfigs}
                                    fusionQueryId={fusionQueryId}
                                    parent="PLP"
                                    sessionId={sessionId}
                                    plpBadge={plpBadge}
                                    isPartsDiagramLinkVisible
                                    handleOnClickPartsDiagramLink={() => {
                                      handleOnClickPartsDiagramLink(product)
                                    }}
                                  />
                                </LazyLoad>
                              </React.Fragment>
                            )}
                          </div>
                          {xfHtml &&
                            getIsPresentOnDevice(
                              presentOnDesktop,
                              presentOnMobile
                            ) &&
                            (i + 1) % position === 0 && (
                              <div
                                className={`${col[promoLayout]} product-listing__tile`}
                              >
                                <PromoBanner
                                  promoJson={promoJson}
                                  xfLink={xfHtml}
                                  col={colVal[promoLayout]}
                                  setPromoData={setPromoData}
                                  position={i}
                                  promoIndex={++promoIndex}
                                  promoData={promoData}
                                  smartCrop={smartCrop}
                                  width={width}
                                  onFilter={onFilter}
                                />
                              </div>
                            )}
                        </React.Fragment>
                      ))}
                    </div>
                    <ProductPagination
                      page={"plp"}
                      {...{
                        totalResults,
                        staticTexts,
                        onLoadMore,
                        curRows,
                        data,
                        displayPrint,
                        displayShare,
                        productsPerPage,
                        onRows,
                        handleBackToTop,
                        width,
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPartsViewerV2Enabled ? (
        <PartsViewerV2
          productId={partData?.productId}
          partId={partData?.partId}
          showModal={partData?.isShowModal}
          page={"PLP results"}
          onClose={onCloseModal}
          fileMap={svgFileMap}
        />
      ) : (
        <PartsViewer
          productId={partData?.productId}
          partId={partData?.partId}
          showModal={partData?.isShowModal}
          page={"PLP results"}
          onClose={onCloseModal}
        />
      )}
    </div>
  )
}
export default ProductListView
